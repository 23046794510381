import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import App from 'App'
import SEO from 'components/SEO'
import Container from 'components/Container'
import Footer from 'components/Footer'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Button from 'components/Button'
import ExternalLink from 'components/Link'

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ExplainerVideoTemplate = ({ pageContext: { title, description, pagePath, link } }) => {
  return (
    <App>
      <SEO title={title} description={description} path={pagePath} />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1 textAlign="center">{title}</H1>
          <Box maxWidth="768px" px="m">
            <Text
              fontSize="l"
              mt="m"
              color="secondaryText"
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Box>
        </Flex>
      </Container>
      <Container maxWidth="1080px" mt="l">
        <Flex width="100%" maxWidth="768px" mt={['l', '0']} justifyContent="center" position="relative" mx="auto">
          <Box maxWidth="768px" height="0" pb="56.25%" width="100%">
            <StyledIframe
              title=""
              src={link}
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Flex>
        <Flex mt={['l', '20']} justifyContent="center" position="relative" mx="auto">
          <Button.Primary as={ExternalLink} href="/explainer-videos">
            <Text>See more explainer videos</Text>
          </Button.Primary>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

ExplainerVideoTemplate.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
}

export default ExplainerVideoTemplate
