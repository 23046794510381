import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Text } from './Typography'
import { formatToNumberWithCommas } from '../helpers'

const AgenciesCounter = ({ ...props }) => {
  const { totalAgencies: { total } = {} } = useStaticQuery(
    graphql`
      query {
        totalAgencies {
          total
        }
      }
    `
  )

  return <Text {...props}>{formatToNumberWithCommas(total)}</Text>
}

export default AgenciesCounter
