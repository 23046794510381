import React from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import AgenciesCounter from 'components/AgenciesCounter'
import Container from 'components/Container'
import { space, fontSizes, colors } from 'theme'
import { Box, Flex, Grid } from './Layout'
import { Text } from './Typography'
import Divider from './Divider'
import logo from '../static/logo/logo_big.png'
import logoUkraine from '../static/logo/logo_big_ukraine.png'
import googleIcon from '../static/svg/google_icon.svg'
import facebookIcon from '../static/svg/facebook_icon.svg'
import instagramIcon from '../static/svg/instagram_icon.svg'
import linkedinIcon from '../static/svg/linkedin_icon.svg'
import youtubeIcon from '../static/svg/youtube_icon.svg'
import twitterIcon from '../static/svg/twitter_icon.svg'

const Logo = styled.img`
  height: auto;
  object-fit: contain;
  margin-top: -18px;
`

const StyledImg = styled.img`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
  transition: filter 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  :not(:hover) {
    filter: grayscale(100%);
  }
`

const StyledLink = styled(Link)`
  margin-bottom: ${space.m};
  font-size: ${fontSizes.s};
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${colors.primary};
  }
`

const Footer = () => {
  return (
    <React.Fragment>
      <Divider />
      <Container>
        <Box mt="xl" mb="m" px={['m', 'xl']}>
          <Grid gridTemplateColumns={['auto', '2fr repeat(4, 1fr)']} gridGap="m">
            <Flex flexDirection="column">
              <Box maxWidth="200px">
                <Logo src={logo} width="200px" />
                <Text mt="l" color="secondaryText">
                  Agency Vista is the new way for brands to find and easily connect with marketing agencies. Explore{' '}
                  <AgenciesCounter as="span" /> verified profiles and reviews to find the right agency for your
                  business.
                </Text>
              </Box>
              <Flex flexWrap="wrap" alignItems="center" pt="s" pb="m">
                <Text mr="s">Follow us</Text>
                <a href="https://facebook.com/agencyvistaofficial" target="_new" rel="noopener noreferrer nofollow">
                  <StyledImg src={facebookIcon} />
                </a>
                <a href="https://instagram.com/agencyvistaofficial" target="_new" rel="noopener noreferrer nofollow">
                  <StyledImg src={instagramIcon} />
                </a>
                <a href="https://www.linkedin.com/company/agencyvista" target="_new" rel="noopener noreferrer nofollow">
                  <StyledImg src={linkedinIcon} />
                </a>
                <a href="https://twitter.com/agencyvista" target="_new" rel="noopener noreferrer nofollow">
                  <StyledImg src={twitterIcon} />
                </a>
              </Flex>
            </Flex>
            <Flex flexDirection="column">
              <Text mb="m" color="secondaryText">
                ABOUT AGENCY VISTA
              </Text>
              <StyledLink as={Link} to="/about/">
                About Us
              </StyledLink>
              <StyledLink as={Link} to="/insights/">
                Insights
              </StyledLink>

              <StyledLink as={Link} to="/careers/">
                Careers
              </StyledLink>
              <StyledLink as={Link} to="/help/">
                Help Center
              </StyledLink>
              <StyledLink
                id="contact"
                as="a"
                href="https://support.agencyvista.com/hc/en-us/requests/new"
                target="_blank"
              >
                Contact
              </StyledLink>
              <StyledLink as={Link} to="/explainer-videos/">
                Explainer Videos
              </StyledLink>
            </Flex>
            <Flex flexDirection="column">
              <Text mb="m" color="secondaryText">
                FOR BUSINESSES
              </Text>
              <StyledLink as={Link} to="/businesses/">
                Overview
              </StyledLink>
              <StyledLink as={Link} to="/find-agency/">
                Find Agency
              </StyledLink>
              <StyledLink as={Link} to="/search">
                Browse Agencies
              </StyledLink>
              <StyledLink as={Link} to="/top-marketing-agencies/">
                Top Marketing Agencies
              </StyledLink>

              <StyledLink as={Link} to="/trust/">
                Why Trust Our Agencies
              </StyledLink>
              <StyledLink as={Link} to="/lists/">
                Browse Lists
              </StyledLink>

              {/* <StyledLink as={Link} to="/industries">
              By Industries
            </StyledLink> */}
            </Flex>
            <Flex flexDirection="column">
              <Text mb="m" color="secondaryText">
                FOR AGENCIES
              </Text>
              <StyledLink as={Link} to="/agencies/">
                Overview
              </StyledLink>
              <StyledLink as={Link} to="/signup">
                Get Listed
              </StyledLink>
              {/* <StyledLink as={Link} to="/businesses/"> */}
              {/*  For Business */}
              {/* </StyledLink> */}
              <StyledLink as={Link} to="/badge">
                Verified Badge
              </StyledLink>

              <StyledLink as={Link} to="/partnership/">
                Partner Programs
              </StyledLink>
            </Flex>
            <Flex flexDirection="column">
              <Text mb="m" color="secondaryText">
                COMMUNITY
              </Text>
              <StyledLink as={Link} to="/hub/">
                Agency Vista Hub
              </StyledLink>
              <StyledLink as={Link} to="/events/">
                Best Marketing Events
              </StyledLink>
              <StyledLink as={Link} to="/experts/">
                Agency Experts
              </StyledLink>
              <StyledLink as={Link} to="/blogging/">
                Guest Blogging
              </StyledLink>
              <StyledLink as={Link} to="/guidelines/">
                Community Guidelines
              </StyledLink>
            </Flex>
          </Grid>
        </Box>
      </Container>
      <Divider />
      <Container>
        <Flex px={['m', 'xl']} flexWrap="wrap" alignItems="center" justifyContent="space-between">
          <Flex flexDirection="row" alignItems="center" pt="m">
            <Box mr={{ mobile: 's', tablet: 'm' }}>
              <StyledLink as={Link} to="/privacy/">
                Privacy Policy
              </StyledLink>
            </Box>
            <Box mr={{ mobile: 's', tablet: 'm' }}>
              <StyledLink as={Link} to="/terms/">
                Terms of use
              </StyledLink>
            </Box>
            <Box mr={{ mobile: 's', tablet: 'm' }}>
              <StyledLink as={Link} to="/vulnerability-reporting-policy/">
                Vulnerability Reporting Policy
              </StyledLink>
            </Box>
          </Flex>
          <Flex alignItems="center" pt="m">
            <Text fontSize="s" color="secondaryText">
              Copyright © {new Date().getFullYear()} Agency Vista LLC. All rights reserved. Lovingly made in NYC.
            </Text>
          </Flex>
        </Flex>
      </Container>
    </React.Fragment>
  )
}

export default Footer
